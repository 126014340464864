import { errorHandler } from 'utils';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Containers
import * as commonJobNotificationActions from 'containers/widgets/CommonJobNotification/actions';

// Components
import { Mui } from 'components';
import { Drawer } from 'components/layouts';

function Export({ exportActions, open, onClose, title, description }) {
  // Hooks
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // States
  const [exporting, setExporting] = useState(false);

  // Actions
  const handleSuccess = res => {
    if (res) {
      const { job: jobUid } = res?.data || {};
      dispatch(commonJobNotificationActions.addJob({ jobUid, status: 'queued' }));
      enqueueSnackbar('A sua solicitação foi enviada com sucesso, em breve você será notificado', {
        variant: 'info',
      });
    }
    onClose();
  };

  const handleFailure = error =>
    enqueueSnackbar(errorHandler(error.response?.data), {
      variant: 'error',
    });

  const handleExport = ({ action, params }) => {
    setExporting(true);
    return action({ ...params })
      .then(handleSuccess)
      .catch(handleFailure)
      .finally(() => setExporting(false));
  };

  const filteredExportActions =
    Array.isArray(exportActions) && exportActions.filter(exportAction => !exportAction?.hidden);

  return (
    <Drawer
      onClose={onClose}
      title={title || 'Exportar'}
      open={open}
      description={description || 'Estas são as opções de relatório disponíveis para exportação'}>
      <Mui.Container>
        <Mui.Card variant="outlined">
          <Mui.List style={{ padding: 0 }}>
            {filteredExportActions.map((exportAction, index) => (
              <Mui.ListItem
                key={exportAction.action}
                button
                onClick={() => handleExport(exportAction)}
                disabled={exporting}
                divider={index < filteredExportActions.length - 1}>
                <Mui.ListItemAvatar>
                  <Mui.Avatar>
                    <Mui.Icon>{exportAction.icon}</Mui.Icon>
                  </Mui.Avatar>
                </Mui.ListItemAvatar>
                <Mui.ListItemText
                  primary={exportAction.label}
                  secondary={exportAction.description}
                />
              </Mui.ListItem>
            ))}
          </Mui.List>
        </Mui.Card>
      </Mui.Container>
    </Drawer>
  );
}

Export.propTypes = {
  exportActions: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Export;
