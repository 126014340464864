import { loadable } from 'utils';

export const Address = loadable(() => import('./Address'), {});
export const BankAccount = loadable(() => import('./BankAccount'), {});
export const BooleanField = loadable(() => import('./BooleanField'), { type: 'circle' });
export const Brand = loadable(() => import('./Brand'), { type: 'text' });
export const Browsable = loadable(() => import('./Browsable'), { type: 'text' });
export const BrowsableStatus = loadable(() => import('./BrowsableStatus'), { type: 'text' });
export const CaptionField = loadable(() => import('./CaptionField'), { type: 'text' });
export const Channel = loadable(() => import('./Channel'), { type: 'text' });
export const Collection = loadable(() => import('./Collection'), { type: 'text' });
export const CurrencyField = loadable(() => import('./CurrencyField'), { type: 'text' });
export const DateField = loadable(() => import('./DateField'), { type: 'text' });
export const Enrollment = loadable(() => import('./Enrollment'), { type: 'text' });
export const EnrollmentRequestOrigin = loadable(() => import('./EnrollmentRequestOrigin'), {});
export const File = loadable(() => import('./File'), { type: 'text' });
export const FollowUpSender = loadable(() => import('./FollowUpSender'), { type: 'data' });
export const Installment = loadable(() => import('./Installment'), { type: 'text' });
export const Institution = loadable(() => import('./Institution'), { type: 'data' });
export const MaskedField = loadable(() => import('./MaskedField'), { type: 'text' });
export const NumberField = loadable(() => import('./NumberField'), { type: 'text' });
export const Organization = loadable(() => import('./Organization'), {});
export const PaymentMethod = loadable(() => import('./PaymentMethod'), {});
export const PercentageField = loadable(() => import('./PercentageField'), { type: 'text' });
export const Picture = loadable(() => import('./Picture'), {});
export const PhoneNumberField = loadable(() => import('./PhoneNumberField'), {});
export const PowerUp = loadable(() => import('./PowerUp'), { type: 'data' });
export const Product = loadable(() => import('./Product'), { type: 'data' });
export const ProductBadges = loadable(() => import('./ProductBadges'), { type: 'data' });
export const ProductOption = loadable(() => import('./ProductOption'), {});
export const ProductPrice = loadable(() => import('./ProductPrice'), {});
export const RelativeTime = loadable(() => import('./RelativeTime'), {});
export const Seller = loadable(() => import('./Seller'), { type: 'data' });
export const Shipping = loadable(() => import('./Shipping'), {});
export const Showcase = loadable(() => import('./Showcase'), {});
export const ShowcasePrivacy = loadable(() => import('./ShowcasePrivacy'), {});
export const Site = loadable(() => import('./Site'), {});
export const State = loadable(() => import('./State'), {});
export const Student = loadable(() => import('./Student'), { type: 'data' });
export const Tag = loadable(() => import('./Tag'), {});
export const User = loadable(() => import('./User'), { type: 'data' });
export const UserRole = loadable(() => import('./UserRole'), {});
