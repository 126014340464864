/* eslint-disable indent */
import { dateObject, toBase64 } from 'utils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Containers
import InputPostcode from 'containers/common/InputPostcode';
import CommonCitySearch from 'containers/widgets/CommonCitySearch';

// Components
import { Button, Mui, MuiLab, TextField } from 'components';
import { Stack } from 'components/layouts';

// Local Components
import * as DisplayData from '../DisplayData';
import * as InputMask from '../InputMask';

function Basic({
  config,
  code,
  uid,
  description,
  isRequired,
  name,
  options,
  type,
  value,
  showLabel,
  onChange,
  inputProps,
}) {
  // States
  const [unknown, setUnknown] = useState(false);
  const [disableFields, setDisableFields] = useState({ line1: true, line2: true, city: true });

  // Actions
  const onPostalCodeCallback = newValues => {
    setUnknown(false);
    const { line1, line2, city, cityUid, country, countryCode, state, stateCode, error } =
      newValues;

    if (error) {
      setUnknown(true);
      setDisableFields({ line1: false, line2: false, city: false });
      onChange(uid, {
        ...value,
        city: '',
        cityUid: '',
        state: '',
        stateCode: '',
        country: '',
        countryCode: '',
      });
    } else {
      setUnknown(false);
      setDisableFields({ line1: line1 !== '', line2: line2 !== '', city: cityUid !== '' });
      onChange(uid, {
        ...value,
        line1,
        line2,
        city,
        cityUid,
        state,
        stateCode,
        country,
        countryCode,
      });
    }
  };

  let parsedValue = value;
  let mask = {};
  if (type === 'phone' && value) mask = { inputComponent: InputMask.MaskedPhoneNumber };
  if (type === 'cpf' && value) mask = { inputComponent: InputMask.MaskedTaxDocumentCPF };
  if (type === 'datetime' && value) {
    const datetimeValue = new Date(value);
    parsedValue = datetimeValue.setHours(datetimeValue.getHours() - 3);
  }
  const emptyCity = {
    city: '',
    cityUid: '',
    state: '',
    stateCode: '',
    country: '',
    countryCode: '',
  };
  switch (type) {
    case 'str':
    case 'phone':
    case 'email':
    case 'cpf':
      return (
        <TextField
          type={type === 'email' ? 'email' : 'text'}
          name={code}
          label={showLabel ? name : ''}
          placeholder={name}
          helperText={description || ''}
          required={isRequired}
          value={value || ''}
          margin="none"
          {...config}
          onChange={e => onChange(uid, e.target.value)}
          InputProps={mask}
          {...inputProps}
        />
      );
    case 'int':
      return (
        <TextField
          type="number"
          name={code}
          label={showLabel ? name : ''}
          placeholder={name}
          helperText={description || ''}
          required={isRequired}
          value={value || ''}
          margin="none"
          {...config}
          onChange={e => onChange(uid, e.target.value)}
          {...inputProps}
        />
      );
    case 'float':
      return (
        <TextField
          type="number"
          name={code}
          label={showLabel ? name : ''}
          placeholder={name}
          helperText={description || ''}
          required={isRequired}
          value={value || ''}
          margin="none"
          decimalSeparator="."
          hideControls
          {...config}
          onChange={e => onChange(uid, e.target.value)}
          {...inputProps}
        />
      );
    case 'bool':
      return (
        <Mui.FormControlLabel
          control={
            <Mui.Switch
              checked={value}
              name={code}
              color="primary"
              onChange={e => onChange(uid, e.target.checked)}
              {...config}
            />
          }
          label={showLabel ? name : ''}
          {...inputProps}
        />
      );
    case 'date':
      return (
        <TextField
          envelope="date"
          name={code}
          label={showLabel ? name : ''}
          placeholder={name}
          helperText={description || ''}
          required={isRequired}
          value={value ? dateObject(value) : ''}
          margin="none"
          {...config}
          onChange={newDate =>
            onChange(uid, newDate ? new Date(newDate).toISOString().split('T')[0] : '')
          }
          {...inputProps}
        />
      );
    case 'datetime':
      return (
        <TextField
          envelope="datetime"
          name={code}
          label={showLabel ? name : ''}
          placeholder={name}
          helperText={description || ''}
          required={isRequired}
          value={parsedValue ? new Date(parsedValue).toISOString().replace('Z', '') : ''}
          {...config}
          onChange={newDate => onChange(uid, newDate ? new Date(newDate).toISOString() : '')}
          {...inputProps}
        />
      );
    case 'file':
      return (
        <Mui.Card variant="outlined" style={{ width: '100%' }}>
          <Mui.CardContent>
            {showLabel ? `${name} ` : ''}
            {value ? (
              <DisplayData.File file={typeof value === 'string' ? value : value?.name || null} />
            ) : null}
          </Mui.CardContent>
          <Mui.CardActions>
            <Button
              component="label"
              variant="outlined"
              color="secondary"
              size="medium"
              startIcon={<Mui.Icon fontSize="small">attachment_icon</Mui.Icon>}>
              Selecionar arquivo
              <input
                type="file"
                hidden
                id={code}
                style={{ display: 'none' }}
                accept="*/*"
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={async event => {
                  const file = event.currentTarget?.files?.[0];
                  onChange(uid, await toBase64(file));
                }}
              />
            </Button>
          </Mui.CardActions>
        </Mui.Card>
      );
    case 'select':
      return (
        <Mui.TextField
          label={showLabel ? name : ''}
          name={code}
          onChange={e => onChange(uid, e.target.value)}
          select
          fullWidth
          SelectProps={{ native: true }}
          // eslint-disable-next-line no-nested-ternary
          value={value ? (typeof value === 'string' ? value : value?.[0]?.uid) : null}
          margin="none"
          variant="outlined"
          {...config}>
          <option value=""> </option>
          {options.map(option => (
            <option key={option.value} value={option.uid}>
              {option.name}
            </option>
          ))}
        </Mui.TextField>
      );
    case 'multi_select':
      return (
        <MuiLab.Autocomplete
          multiple
          fullWidth
          value={value || []}
          options={options}
          onChange={(event, selectedOption) => onChange(uid, selectedOption)}
          getOptionSelected={(option, selected) => option?.uid === selected?.uid}
          disableCloseOnSelect
          getOptionLabel={option => (option?.name ? option?.name : String(option))}
          renderOption={(option, { selected }) => (
            <Mui.Grid container spacing={1} wrap="nowrap" alignItems="center">
              <Mui.Grid item>
                <Mui.Checkbox checked={selected} />
              </Mui.Grid>
              <Mui.Grid item xs>
                {option?.name}
              </Mui.Grid>
            </Mui.Grid>
          )}
          renderTags={(val, getTagProps) =>
            val?.map((option, index) => (
              <Mui.Chip label={option?.name} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <TextField margin="none" variant="outlined" label={name} {...params} />
          )}
        />
      );
    case 'address':
      return (
        <Mui.Paper variant="outlined">
          <Mui.Box p={2}>
            <Stack
              layout={[
                { xs: 12 },
                { xs: 12 },
                { xs: 12 },
                { xs: 12, sm: 8 },
                { xs: 12, sm: 4 },
                { xs: 12, sm: 6 },
                { xs: 12, sm: 6 },
                { xs: 12 },
                { xs: 12 },
              ]}>
              <Mui.Typography variant="body1">{name}</Mui.Typography>
              <InputPostcode
                value={value?.postcode}
                onChange={newValue => onChange(uid, { ...value, postcode: newValue })}
                onPostalCodeCallback={onPostalCodeCallback}
              />
              {unknown && (
                <MuiLab.Alert severity="warning" onClose={() => setUnknown(false)}>
                  <MuiLab.AlertTitle>CEP não localizado</MuiLab.AlertTitle>
                  Não localizamos o CEP <strong>{value?.postcode}</strong> em nossa base de
                  endereços. Atenção ao preencher seu endereço completo abaixo
                </MuiLab.Alert>
              )}
              <TextField
                name="line1"
                type="text"
                margin="none"
                placeholder="Logradouro"
                label={showLabel ? 'Logradouro' : ''}
                required
                value={value?.line1 || ''}
                onChange={e => onChange(uid, { ...value, line1: e.target.value })}
                inputProps={{ disabled: disableFields.line1 }}
              />
              <TextField
                name="line4"
                type="text"
                margin="none"
                placeholder="Número"
                label={showLabel ? 'Número' : ''}
                required
                value={value?.line4 || ''}
                onChange={e => onChange(uid, { ...value, line4: e.target.value })}
              />
              <TextField
                name="line2"
                type="text"
                margin="none"
                placeholder="Bairro"
                label={showLabel ? 'Bairro' : ''}
                required
                value={value?.line2 || ''}
                onChange={e => onChange(uid, { ...value, line2: e.target.value })}
                inputProps={{ disabled: disableFields.line1 }}
              />
              <TextField
                name="line3"
                type="text"
                margin="none"
                placeholder="Complemento"
                label={showLabel ? 'Complemento' : ''}
                value={value?.line3 || ''}
                {...config}
                onChange={e => onChange(uid, { ...value, line3: e.target.value })}
                {...inputProps}
              />
              <CommonCitySearch
                selectedCity={
                  value?.cityUid
                    ? {
                        uid: value.cityUid,
                        name: value.city,
                        state: {
                          uid: '',
                          name: value.state,
                          code: value.stateCode,
                          country: {
                            uid: '',
                            name: value.country,
                            code: value.countryCode,
                          },
                        },
                      }
                    : null
                }
                onSelect={option =>
                  onChange(
                    uid,
                    option
                      ? {
                          ...value,
                          cityUid: option.uid,
                          city: option.name,
                          state: option.state.name,
                          stateCode: option.state.code,
                          country: option.state.country.name,
                          countryCode: option.state.country.code,
                        }
                      : { ...value, ...emptyCity },
                  )
                }
                disabled={disableFields.city}
              />
            </Stack>
          </Mui.Box>
        </Mui.Paper>
      );
    default:
      return '';
  }
}

Basic.propTypes = {
  config: PropTypes.object,
  code: PropTypes.string,
  uid: PropTypes.string,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.array,
  showLabel: PropTypes.bool,
  type: PropTypes.oneOf([
    'str',
    'email',
    'phone',
    'cpf',
    'int',
    'float',
    'bool',
    'date',
    'datetime',
    'file',
    'select',
    'multi_select',
    'address',
  ]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
};

export default Basic;
