import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import MuiIcon from '@material-ui/core/Icon';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';

// Helper function to get nested values
const getNestedValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

// eslint-disable-next-line sonarjs/cognitive-complexity
function Text(props) {
  const {
    id,
    name,
    formik,
    messages,
    inputProps,
    InputProps,
    type,
    envelope,
    value,
    append,
    minDate,
    onChange,
    ...otherProps
  } = props;

  if (formik && !name) throw Error('Using formik requires name prop');

  // This section of the code handles formik integration
  const hasMessage = messages && messages[name];
  const helperText = hasMessage && messages[name].helperText;
  const label = hasMessage && messages[name].label;
  const placeholder = hasMessage && messages[name].placeholder;
  const rawValue = formik ? getNestedValue(formik.values, name) : value;

  // Check if there are errors and if the input was touched
  const hasTouched = formik && getNestedValue(formik.touched, name);
  const isTouched = hasTouched && getNestedValue(formik.touched, name);
  const errorText = formik && getNestedValue(formik.errors, name);
  const hasError = (formik?.validateOnMount || isTouched) && errorText !== undefined;

  const handleChangeDate = newDate => {
    if (formik) {
      formik.setFieldTouched(name, true);
      formik.setFieldValue(name, newDate, true);
    }
  };

  const pickerEndAdornment = (
    <MuiIconButton
      size="small"
      onClick={e => {
        e.stopPropagation();
        return onChange ? onChange?.(null) : handleChangeDate(null);
      }}>
      <MuiIcon fontSize="small">clear</MuiIcon>
    </MuiIconButton>
  );

  const pickerProps = { ...(rawValue ? { endAdornment: pickerEndAdornment } : {}) };

  if (envelope === 'date')
    return (
      <DatePicker
        id={id || name}
        name={name}
        label={label}
        fullWidth
        inputVariant="outlined"
        format="dd/MM/yyyy"
        autoOk
        clearable
        showTodayButton
        animateYearScrolling
        value={rawValue || null}
        onChange={onChange || handleChangeDate}
        onBlur={formik && formik.handleBlur}
        minDate={minDate}
        minDateMessage={`Data escolhida não poder ser anterior à ${
          minDate && minDate.format('DD/MM/YYYY')
        }`}
        error={hasError}
        helperText={(hasError && errorText) || helperText}
        clearLabel="Limpar"
        todayLabel="Hoje"
        cancelLabel="Cancelar"
        InputProps={{
          ...pickerProps,
          ...InputProps,
        }}
        {...otherProps}
      />
    );

  if (envelope === 'datetime')
    return (
      <DateTimePicker
        id={id || name}
        name={name}
        label={label}
        fullWidth
        inputVariant="outlined"
        ampm={false}
        format="dd/MM/yyyy HH:mm"
        autoOk
        clearable
        showTodayButton
        animateYearScrolling
        value={rawValue}
        onChange={onChange || handleChangeDate}
        onBlur={formik && formik.handleBlur}
        minDate={minDate}
        minDateMessage={`Data escolhida não poder ser anterior à ${
          minDate && minDate.format('DD/MM/YYYY HH:mm')
        }`}
        error={hasError}
        helperText={(hasError && errorText) || helperText}
        clearLabel="Limpar"
        todayLabel="Hoje"
        cancelLabel="Cancelar"
        InputProps={{
          ...pickerProps,
          ...InputProps,
        }}
        {...otherProps}
      />
    );

  const envelopeProps = () => {
    if (envelope === 'currency')
      return {
        type: 'number',
        inputProps: { min: 0, step: 'any', ...inputProps },
        InputProps: {
          startAdornment: <MuiInputAdornment position="start">R$</MuiInputAdornment>,
          ...InputProps,
        },
      };

    if (envelope === 'percentage')
      return {
        type: 'number',
        inputProps: { min: 0, max: 100, step: 0.1, ...inputProps },
        InputProps: {
          endAdornment: <MuiInputAdornment position="end">%</MuiInputAdornment>,
          ...InputProps,
        },
      };

    if (envelope === 'custom') {
      if (!append || (append && (!append.content || !append.position)))
        throw Error('No append object with content and position');

      return {
        InputProps: {
          ...(append.position === 'start' && {
            startAdornment: (
              <MuiInputAdornment position="start">{append.content}</MuiInputAdornment>
            ),
          }),
          ...(append.position === 'end' && {
            endAdornment: <MuiInputAdornment position="end">{append.content}</MuiInputAdornment>,
          }),
          ...InputProps,
        },
      };
    }

    return {};
  };

  return (
    <MuiTextField
      variant="outlined"
      id={id || name}
      name={name}
      error={hasError}
      helperText={(hasError && errorText) || helperText}
      label={label}
      placeholder={placeholder}
      onChange={onChange || (formik && formik.handleChange)}
      onBlur={formik && formik.handleBlur}
      value={rawValue}
      type={type}
      InputProps={InputProps}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputProps}
      {...envelopeProps()}
      {...otherProps}
    />
  );
}

Text.propTypes = {
  errorText: PropTypes.string,
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    validateOnMount: PropTypes.bool,
  }),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  messages: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  type: PropTypes.any,
  envelope: PropTypes.oneOf(['currency', 'date', 'datetime', 'time', 'percentage', 'custom']),
  append: PropTypes.any,
  minDate: PropTypes.any,
};

Text.defaultProps = {
  fullWidth: true,
  margin: 'normal',
};

export default Text;
