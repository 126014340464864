import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';

function Bar({ title, actions }) {
  return (
    <Mui.Card variant="outlined">
      <Mui.Box p={2}>
        <Mui.Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignContent="center"
          alignItems="center">
          <Mui.Grid item xs>
            <Mui.Box display="flex" alignItems="center">
              <Mui.Icon color="secondary">assignment</Mui.Icon>
              <Mui.Box mr={1} />
              <Mui.Typography color="secondary" variant="h6" noWrap>
                {title}
              </Mui.Typography>
            </Mui.Box>
          </Mui.Grid>
          {actions.map((action, index) => (
            <Mui.Grid item key={`actionbar-button-${index.toString()}`}>
              <Mui.Button
                size="medium"
                variant={action.variant || 'outlined'}
                color={action.color || 'secondary'}
                onClick={action.onClick}
                startIcon={action.icon ? <Mui.Icon>{action.icon}</Mui.Icon> : null}>
                {action.title}
              </Mui.Button>
            </Mui.Grid>
          ))}
        </Mui.Grid>
      </Mui.Box>
    </Mui.Card>
  );
}

Bar.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
};

export default Bar;
