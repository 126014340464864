import { loadable } from 'utils';

export const Checkout = loadable(() => import('./Checkout'), {});
export const Collection = loadable(() => import('./Collection'), {});
export const Create = loadable(() => import('./Create'), {});
export const Maintenance = loadable(() => import('./Maintenance'), {});
export const Page = loadable(() => import('./Page'), {});
export const Site = loadable(() => import('./Site'), {});
export const SiteDangerZone = loadable(() => import('./SiteDangerZone'), {});
export const Seller = loadable(() => import('./Seller'), {});
export * as Start from './Start';
export const Support = loadable(() => import('./Support'), {});
export const Layout = loadable(() => import('./Appearance/Layout'), {});
export const Theme = loadable(() => import('./Appearance/Theme'), {});
