import { useInstitution } from 'hooks';
import { useParams } from 'react-router-dom';
import envs from 'envs';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';
import { NavBar as DefaultNavBar } from 'components/layouts';

function NavBar(props) {
  const { onMobileClose, openMobile, ...restProps } = props;

  // Custom hooks
  const { institution } = useInstitution();
  const { institutionSlug } = useParams();

  // Constants
  const { logo: image, cover, displayName, seller, taxDocument: info } = institution || {};
  const sellerSlug = seller?.slug || null;
  const sellerLogo = seller?.logo || null;
  const institutionsPath = `/institutions/${institutionSlug}`;

  // Props
  const profile = { image, cover, displayName, profileUrl: institutionsPath, info };

  const items = [
    {
      subheader: 'Sua conta',
      items: [
        {
          title: 'Ir para vendedor',
          href: ['/sellers', sellerSlug].join('/'),
          avatar: newProps => <Mui.Avatar src={sellerLogo} {...newProps} />,
          invisible: !sellerSlug,
        },
        {
          title: 'Início',
          href: [institutionsPath].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>home_outlined</Mui.Icon>,
        },
        {
          title: 'Perfil',
          href: [institutionsPath, 'profile'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>business</Mui.Icon>,
        },
        {
          title: 'Página pública',
          href: ['/e', institutionSlug].join('/'),
          target: '_blank',
          icon: newProps => <Mui.Icon {...newProps}>public</Mui.Icon>,
          info: () => <Mui.Icon fontSize="small">open_in_new</Mui.Icon>,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Eskolare Insights',
      items: [
        {
          title: 'Indicadores',
          icon: newProps => <Mui.Icon {...newProps}>dashboard</Mui.Icon>,
          items: [
            { title: 'Geral', href: [institutionsPath, 'indicators', 'general'].join('/') },
            {
              title: 'Acordo de Vendas',
              href: [institutionsPath, 'indicators', 'sales-agreement'].join('/'),
            },
          ],
        },
        {
          title: 'Avaliações de usuários',
          href: [institutionsPath, 'nps-score'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>reviews</Mui.Icon>,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Gestão',
      items: [
        {
          title: 'Pedidos',
          href: [institutionsPath, 'orders'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>check_box_outlined</Mui.Icon>,
        },
        {
          title: 'Entrega na instituição',
          href: [institutionsPath, 'institution-shipment'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>home_work</Mui.Icon>,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Suporte da Plataforma',
      items: [
        {
          title: 'Atendimentos',
          href: [institutionsPath, 'support', 'platform-tickets'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>question_answer</Mui.Icon>,
          invisible: institutionSlug === envs.APPLICATION_SLUG,
        },
        {
          title: 'Artigos de ajuda',
          href: [institutionsPath, 'support', 'platform-articles'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>help</Mui.Icon>,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Suporte aos Clientes',
      items: [
        {
          title: 'Chamados',
          href: [institutionsPath, 'support', 'tickets'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>support_agent</Mui.Icon>,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Relacionamento',
      items: [
        {
          title: 'Parcerias',
          href: [institutionsPath, 'partnerships'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>assignment</Mui.Icon>,
        },
        {
          title: 'Matrículas',
          href: [institutionsPath, 'enrollments'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>school_outlined</Mui.Icon>,
        },
        {
          title: 'Tenho interesse',
          href: [institutionsPath, 'product-alerts'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>notifications_active</Mui.Icon>,
        },
      ].filter(item => !item.invisible),
    },
    {
      subheader: 'Cadastros',
      items: [
        {
          title: 'Censo Escolar',
          href: [institutionsPath, 'school-census'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>poll</Mui.Icon>,
        },
        {
          title: 'Venda mais',
          href: [institutionsPath, 'sell-more'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>monetization_on</Mui.Icon>,
        },
        {
          title: 'Páginas',
          href: [institutionsPath, 'pages'].join('/'),
          icon: newProps => <Mui.Icon {...newProps}>description</Mui.Icon>,
          isStaff: true,
        },
      ].filter(item => !item.invisible),
    },
  ];

  return (
    <DefaultNavBar
      onMobileClose={onMobileClose}
      openMobile={openMobile}
      items={items}
      profile={profile}
      {...restProps}></DefaultNavBar>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
