import { loadable } from 'utils';

export const Category = loadable(() => import('./Category'), {});
export const Calendar = loadable(() => import('./Calendar'), {});
export const CalendarDates = loadable(() => import('./CalendarDates'), {});
export const Configuration = loadable(() => import('./Configuration'), {});
export const Filter = loadable(() => import('./Filter'), {});
export const FollowUp = loadable(() => import('./FollowUp'), {});
export const FollowUpTemplate = loadable(() => import('./FollowUpTemplate'), {});
export const ImportWizard = loadable(() => import('./ImportWizard'), {});
export const Schedule = loadable(() => import('./Schedule'), {});
export const SlaPolicy = loadable(() => import('./SlaPolicy'), {});
export const Subscriber = loadable(() => import('./Subscriber'), {});
export const Ticket = loadable(() => import('./Ticket'), {});
