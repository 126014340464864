import { useFetch } from 'hooks';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Components
import { Mui, MuiLab, TextField } from 'components';

// Local Components
import * as api from './api';

function CommonCitySearch({ disabled = false, selectedCity, onSelect }) {
  // States
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [reload, setReload] = useState(false);
  const [debounceSearch] = useState(() => debounce(() => setReload(true), 500));

  // Fetch
  const { data, error } = useFetch([api.endpoints.list(), { search: debouncedSearch }]);
  const { results: options = [] } = data || {};
  const loading = !data && !error;

  // Effects
  useEffect(() => {
    if (reload) {
      setReload(false);
      setDebouncedSearch(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <MuiLab.Autocomplete
      disabled={disabled}
      loading={loading}
      limitTags={3}
      getOptionSelected={(option, value) => option.uid === value.uid}
      getOptionLabel={option => [option.name, option.state.name].join('/')}
      options={options}
      open={open}
      onChange={(event, newValue) => {
        setOpen(false);
        onSelect(newValue);
      }}
      value={selectedCity}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
        debounceSearch();
      }}
      renderOption={option => (
        <Mui.Box display="flex" alignItems="flex-end">
          <Mui.Box mr={2}>
            <Mui.Typography variant="body1" component="span" noWrap>
              {option.name}
            </Mui.Typography>
          </Mui.Box>
          <Mui.Typography variant="body2" color="textSecondary" noWrap>
            {option.state.name}
          </Mui.Typography>
        </Mui.Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          margin="none"
          required
          label="Cidade/Estado"
          placeholder="Digite o nome da cidade"
          value={search}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <Mui.CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

CommonCitySearch.propTypes = {
  disabled: PropTypes.bool,
  selectedCity: PropTypes.object,
  onSelect: PropTypes.func,
};

export default CommonCitySearch;
