import { loadable } from 'utils';

export * as InstitutionContract from './InstitutionContract';
export * as InstitutionEnrollment from './InstitutionEnrollment';
export * as InstitutionStudent from './InstitutionStudent';
export const CollectionNotification = loadable(() => import('./CollectionNotification'), {});
export const Configuration = loadable(() => import('./Configuration'), {});
export const Grade = loadable(() => import('./Grade'), {});
export const ImportWizard = loadable(() => import('./ImportWizard'), {});
export const Installation = loadable(() => import('./Installation'), {});
