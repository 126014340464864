import { autoPlay, bindKeyboard } from 'react-swipeable-views-utils';
import DefaultSwipeableViews from 'react-swipeable-views';

import 'react-credit-cards/es/styles-compiled.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import * as Mui from './Mui';
import * as MuiLab from './MuiLab';

const SwipeableViews = bindKeyboard(autoPlay(DefaultSwipeableViews));

export { default as Avatar } from '@material-ui/core/Avatar';
export { default as Badge } from '@material-ui/core/Badge';
export { default as Box } from '@material-ui/core/Box';
export { default as Button } from './eskolare-ui/Button';
export { default as Cards } from 'react-credit-cards';
export { default as Chip } from '@material-ui/core/Chip';
export { default as Divider } from '@material-ui/core/Divider';
export { default as Grid } from '@material-ui/core/Grid';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as Link } from '@material-ui/core/Link';
export { default as Logo } from './eskolare-ui/Logo';
export { default as MaskedInput } from 'react-text-mask';
export { default as NativeSelect } from './eskolare-ui/NativeSelect';
export { default as Paper } from '@material-ui/core/Paper';
export { default as PasswordField } from './eskolare-ui/Field/Password';
export { default as PerfectScrollbar } from 'react-perfect-scrollbar';
export { default as Radio } from './eskolare-ui/Radio';
export { default as Slick } from './eskolare-ui/Slick';
export { default as SocialButton } from './eskolare-ui/SocialButton';
export { default as Switch } from './eskolare-ui/Switch';
export { default as TextField } from './eskolare-ui/Field/Text';
export { default as TextFieldNested } from './eskolare-ui/Field/TextNested';
export { default as FilesDropzone } from './eskolare-ui/FilesDropzone';
export { default as TextMaskCreditCardNumber } from './eskolare-ui/Field/TextMaskCreditCardNumber';
export { default as TextMaskCreditCardValidDate } from './eskolare-ui/Field/TextMaskCreditCardValidDate';
export { default as TextMaskDate } from './eskolare-ui/Field/TextMaskDate';
export { default as ThemeBaseProvider } from './eskolare-ui/ThemeBaseProvider';
export { default as Typography } from '@material-ui/core/Typography';
export { Mui, MuiLab, SwipeableViews };
