/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';

import * as images from 'images';
import bytesToSize from 'utils/bytesToSize';
import Icon from '@material-ui/core/Icon';

import Button from './Button';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const i18n = {
  'pt-br': {
    header: 'Selecione arquivo(s)',
    instructions: 'Solte o(s) arquivo(s) nesta área ou clique aqui para selecionar o(s) arquivo(s)',
    invalidType: 'Formato de arquivo inválido',
    removeAll: 'Remover todos',
    uploadFiles: 'Enviar',
    next: 'Próximo',
  },
};

function FilesDropzone(props) {
  const {
    className,
    localization,
    loading,
    acceptTypes,
    maxFiles,
    maxSize,
    nextStep,
    skipSendStep,
    onSubmit,
    ...restProps
  } = props;

  const classes = useStyles();

  const [files, setFiles] = useState([]);

  const { header, instructions, invalidType, uploadFiles, next, removeAll } = i18n[localization];

  const handleDrop = useCallback(acceptedFiles => {
    if (skipSendStep) {
      onSubmit(acceptedFiles, { onFinish: () => setFiles([]) });
      setFiles(prevFiles => [...prevFiles].concat(acceptedFiles));
    } else {
      setFiles(prevFiles => [...prevFiles].concat(acceptedFiles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleSubmit = () => {
    onSubmit(files, { onFinish: () => setFiles([]) });
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections, acceptedFiles } = useDropzone({
    onDrop: handleDrop,
    accept: acceptTypes,
    maxFiles: maxFiles || 0,
    maxSize: maxSize || 10485760,
  });

  return (
    <div className={clsx(classes.root, className)} {...restProps}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          <img alt="Select file" className={classes.image} src={images.illustrationsUploadFile} />
        </div>
        <div>
          <Typography gutterBottom variant="h3" align="center">
            {header}
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1" align="center">
              {instructions}
            </Typography>
          </Box>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {fileRejections.map(({ file }, i) => (
                <ListItem divider={i < files.length - 1} key={file.path}>
                  <ListItemIcon>
                    <Icon color="error">error_outline</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={file.path}
                    primaryTypographyProps={{ variant: 'h5', color: 'error' }}
                    secondary={invalidType}
                  />
                </ListItem>
              ))}
              {acceptedFiles.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <Icon>file_copy</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          {!skipSendStep && (
            <div className={classes.actions}>
              <Button onClick={handleRemoveAll} variant="outlined" size="small">
                {removeAll}
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                loading={loading}
                onClick={handleSubmit}>
                {nextStep ? next : uploadFiles}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  acceptTypes: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  nextStep: PropTypes.bool,
  skipSendStep: PropTypes.bool,
  className: PropTypes.string,
  localization: PropTypes.string,
};

FilesDropzone.defaultProps = {
  localization: 'pt-br',
};

export default FilesDropzone;
