/* eslint-disable sonarjs/no-duplicate-string */
import { Redirect } from 'react-router-dom';
import React, { lazy } from 'react';

// Layouts
import EmptyLayout from 'layouts/EmptyLayout';
import SellerLayout from 'layouts/SellerLayout';

// Containers
import SellerGuard from '../SellerGuard';

export default [
  {
    exact: true,
    path: '/sellers/register',
    layout: EmptyLayout,
    component: lazy(() => import('pages/sellers/SellerRegistrationPage')),
  },
  {
    path: '/sellers/:sellerSlug',
    layout: SellerLayout,
    guard: SellerGuard,
    routes: [
      {
        exact: true,
        path: '/sellers/:sellerSlug/customers',
        component: lazy(() => import('pages/sellers/SellerCustomerPage')),
        permissionKey: 'cx.customers',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/customers/:customerUid',
        component: lazy(() => import('pages/sellers/SellerCustomerDetailPage')),
        permissionKey: 'cx.customers',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/partnerships/:institutionSlug',
        component: lazy(() => import('pages/sellers/SellerPartnershipDetailsPage')),
        permissionKey: 'partnerships.partnerships',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/partnerships',
        component: lazy(() => import('pages/sellers/SellerPartnershipPage')),
        permissionKey: 'partnerships.partnerships',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/brands',
        component: lazy(() => import('pages/sellers/SellerBrandPage')),
        permissionKey: 'catalog.brands',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/product-classes',
        component: lazy(() => import('pages/sellers/SellerProductClassesPage')),
        permissionKey: 'catalog.product_classes',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/product-classes/:productClassSlug',
        component: lazy(() => import('pages/sellers/SellerProductClassesDetailsPage')),
        permissionKey: 'catalog.product_classes',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/financial/income-report/:year',
        component: lazy(() => import('pages/sellers/SellerFinancialIncomeReportPage')),
        permissionKey: 'financial.statement',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/financial/fees',
        component: lazy(() => import('pages/sellers/SellerFinancialArrangementsPage')),
        permissionKey: 'financial.commissions',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/financial/sales-agreements-commission-accounts',
        component: lazy(() => import('pages/sellers/SellerSalesAgreementsCommissionAccountsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/financial/sales-agreements-commission-accounts/:commissionAccountUid',
        component: lazy(() =>
          import('pages/sellers/SellerSalesAgreementsCommissionAccountsDetailsPage'),
        ),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/financial/:withdrawalUid',
        component: lazy(() => import('pages/sellers/SellerFinancialWithdrawalPage')),
        permissionKey: 'financial.statement',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/financial',
        component: lazy(() => import('pages/sellers/SellerFinancialPage')),
        permissionKey: 'financial.statement',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/payments',
        component: lazy(() => import('pages/sellers/SellerPaymentPage')),
        permissionKey: 'payments.payments',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/logistics',
        component: lazy(() => import('pages/sellers/SellerLogisticPage')),
        permissionKey: 'logistics.warehouses',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/billing-groups',
        component: lazy(() => import('pages/sellers/SellerBillingGroupsPage')),
        permissionKey: 'orders.orders',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/institution-shipment',
        component: lazy(() => import('pages/sellers/SellerInstitutionShipmentPage')),
        permissionKey: 'logistics.institution_shipments',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/institution-shipment/:shipmentCode',
        component: lazy(() => import('pages/sellers/SellerInstitutionShipmentDetailsPage')),
        permissionKey: 'logistics.institution_shipments',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/prices',
        component: lazy(() => import('pages/sellers/SellerPricePage')),
        permissionKey: 'pricing.prices',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/stock-alerts',
        component: lazy(() => import('pages/sellers/SellerStockAlertPage')),
        permissionKey: 'logistics.stock_alerts',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/product-alerts',
        component: lazy(() => import('pages/sellers/SellerProductAlertPage')),
        permissionKey: 'catalog.product_alerts',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/products/:productSlug/variants/:variantSlug',
        component: lazy(() => import('pages/sellers/SellerProductVariantEditPage')),
        permissionKey: 'catalog.products',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/products/:productSlug',
        component: lazy(() => import('pages/sellers/SellerProductEditPage')),
        permissionKey: 'catalog.products',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/products',
        component: lazy(() => import('pages/sellers/SellerProductPage')),
        permissionKey: 'catalog.products',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/profile',
        component: lazy(() => import('pages/sellers/SellerProfilePage')),
        permissionKey: 'account.profile',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/suggestions',
        component: lazy(() => import('pages/sellers/SellerProductSuggestionsPage')),
        permissionKey: 'catalog.suggestions',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/concierge/view/:conciergeCode',
        component: lazy(() => import('pages/sellers/SellerConciergeDetailPage')),
        permissionKey: 'cx.concierge',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/concierge',
        component: lazy(() => import('pages/sellers/SellerConciergePage')),
        permissionKey: 'cx.concierge',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/integrations',
        component: lazy(() => import('pages/sellers/SellerIntegrationPage')),
        permissionKey: 'integrations.integrations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/power-ups',
        component: lazy(() => import('pages/sellers/SellerPowerUpPage')),
        permissionKey: 'integrations.powerups',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/power-ups/:powerUpCode',
        component: lazy(() => import('pages/sellers/SellerPowerUpDetailsPage')),
        permissionKey: 'integrations.powerups',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/orders/:orderNumber',
        component: lazy(() => import('pages/sellers/SellerOrderDetailsPage')),
        permissionKey: 'orders.orders',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/orders',
        component: lazy(() => import('pages/sellers/SellerOrderPage')),
        permissionKey: 'orders.orders',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tax-invoices',
        component: lazy(() => import('pages/sellers/SellerTaxInvoicePage')),
        permissionKey: 'tax_invoice.configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tax-invoices/service/:taxInvoiceUid',
        component: lazy(() => import('pages/sellers/SellerTaxInvoiceServiceDetailsPage')),
        permissionKey: 'tax_invoice.configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tax-invoices/product/:taxInvoiceUid',
        component: lazy(() => import('pages/sellers/SellerTaxInvoiceProductDetailsPage')),
        permissionKey: 'tax_invoice.configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/options/:productOptionCode',
        component: lazy(() => import('pages/sellers/SellerProductOptionEditPage')),
        permissionKey: 'catalog.products',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/options',
        component: lazy(() => import('pages/sellers/SellerProductOptionPage')),
        permissionKey: 'catalog.products',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/items',
        component: lazy(() => import('pages/sellers/SellerItemPage')),
        permissionKey: 'shops.allocations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/vouchers/view/:voucherUid',
        component: lazy(() => import('pages/sellers/SellerVoucherDetailsPage')),
        permissionKey: 'promotions.vouchers',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/vouchers',
        component: lazy(() => import('pages/sellers/SellerVoucherPage')),
        permissionKey: 'promotions.vouchers',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/ranges',
        component: lazy(() => import('pages/sellers/SellerRangePage')),
        permissionKey: 'promotions.ranges',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/offers/view/:offerUid',
        component: lazy(() => import('pages/sellers/SellerOfferDetailsPage')),
        permissionKey: 'promotions.offers',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/offers',
        component: lazy(() => import('pages/sellers/SellerOfferPage')),
        permissionKey: 'promotions.offers',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tuition/:tuitionTemplateCode',
        component: lazy(() => import('pages/sellers/SellerTuitionTemplateDetailPage')),
        permissionKey: 'pricing.tuition_templates',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tuition',
        component: lazy(() => import('pages/sellers/SellerTuitionTemplatePage')),
        permissionKey: 'pricing.tuition_templates',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/contract/:contractTemplateUid',
        component: lazy(() => import('pages/sellers/SellerContractTemplateDetailsPage')),
        permissionKey: 'pricing.contract_templates',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/contract',
        component: lazy(() => import('pages/sellers/SellerContractTemplatePage')),
        permissionKey: 'pricing.contract_templates',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tasks/:taskCode',
        component: lazy(() => import('pages/sellers/SellerTaskDetailPage')),
        permissionKey: 'tasks.tasks',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tasks',
        component: lazy(() => import('pages/sellers/SellerTaskPage')),
        permissionKey: 'tasks.tasks',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/groups/:groupUid',
        component: lazy(() => import('pages/sellers/SellerGroupDetailsPage')),
        permissionKey: 'permissions.groups',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/groups',
        component: lazy(() => import('pages/sellers/SellerGroupPage')),
        permissionKey: 'permissions.groups',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/users/:staffUid',
        component: lazy(() => import('pages/sellers/SellerUserDetailsPage')),
        permissionKey: 'permissions.users',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/users',
        component: lazy(() => import('pages/sellers/SellerUserPage')),
        permissionKey: 'permissions.users',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/support/tickets/:ticketId',
        component: lazy(() => import('pages/sellers/SellerSupportTicketDetailsPage')),
        permissionKey: 'support.can_view_tickets',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/support/tickets',
        component: lazy(() => import('pages/sellers/SellerSupportTicketsPage')),
        permissionKey: 'support.can_view_tickets',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/support/platform-tickets/:platformTicketId',
        component: lazy(() => import('pages/sellers/SellerSupportPlatformTicketDetailsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/support/platform-tickets',
        component: lazy(() => import('pages/sellers/SellerSupportPlatformTicketsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/support/platform-articles/:platformArticleSlug',
        component: lazy(() => import('pages/sellers/SellerSupportPlatformArticleDetailsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/support/platform-articles',
        component: lazy(() => import('pages/sellers/SellerSupportPlatformArticlesPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/support/configuration',
        component: lazy(() => import('pages/sellers/SellerSupportConfigurationPage')),
        permissionKey: 'support.configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/abandoned-carts',
        component: lazy(() => import('pages/sellers/SellerAbandonedCartsPage')),
        permissionKey: 'cx.baskets',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/nps-score',
        component: lazy(() => import('pages/sellers/SellerNpsScorePage')),
        permissionKey: 'cx.baskets',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/featured',
        component: lazy(() => import('pages/sellers/SellerFeaturedPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/featured-template',
        component: lazy(() => import('pages/sellers/SellerFeaturedTemplatePage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sites/:subdomain',
        component: lazy(() => import('pages/sellers/SellerSiteDetailsPage')),
        permissionKey: 'shops.sites',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sites',
        component: lazy(() => import('pages/sellers/SellerSitePage')),
        permissionKey: 'shops.sites',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/channels/:channelUid',
        component: lazy(() => import('pages/sellers/SellerChannelDetailsPage')),
        permissionKey: 'shops.channels',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/channels',
        component: lazy(() => import('pages/sellers/SellerChannelPage')),
        permissionKey: 'shops.channels',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/channels-access-request',
        component: lazy(() => import('pages/sellers/SellerChannelAccessRequestPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/tags',
        component: lazy(() => import('pages/sellers/SellerTagsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/indicators/abandoned-carts',
        component: lazy(() => import('pages/sellers/SellerIndicatorsAbandonedCartsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/indicators/general',
        component: lazy(() => import('pages/sellers/SellerIndicatorsGeneralPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/indicators/financial',
        component: lazy(() => import('pages/sellers/SellerIndicatorsFinancialPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/indicators/orders',
        component: lazy(() => import('pages/sellers/SellerIndicatorsOrdersPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/indicators/eskolare-desk',
        component: lazy(() => import('pages/sellers/SellerIndicatorsEskolareDeskPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/indicators/sales-agreement',
        component: lazy(() => import('pages/sellers/SellerIndicatorsSalesAgreementPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sales-agreements/:salesAgreementCode',
        component: lazy(() => import('pages/sellers/SellerSalesAgreementDetailsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sales-agreements',
        component: lazy(() => import('pages/sellers/SellerSalesAgreementsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/billing/plan/:billingPlanCode',
        component: lazy(() => import('pages/sellers/SellerBillingPlanDetailsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/billing/plan',
        component: lazy(() => import('pages/sellers/SellerBillingPlanPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/billing/invoices/:invoiceCode',
        component: lazy(() => import('pages/sellers/SellerBillingInvoiceDetailsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/billing/invoices',
        component: lazy(() => import('pages/sellers/SellerBillingInvoicePage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/billing/credit-cards',
        component: lazy(() => import('pages/sellers/SellerBillingCreditCardPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/billing/bank-accounts',
        component: lazy(() => import('pages/sellers/SellerBillingBankAccountsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/installation-guide',
        component: lazy(() => import('pages/sellers/SellerSchoolManagementInstallationGuidePage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/institution-students',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementInstitutionStudentsPage'),
        ),
        permissionKey: 'school_management.can_view_students',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/enrollments',
        component: lazy(() => import('pages/sellers/SellerSchoolManagementEnrollmentsPage')),
        permissionKey: 'school_management.can_view_configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/enrollments/:institutionStudentEnrollmentUid',
        component: lazy(() => import('pages/sellers/SellerSchoolManagementEnrollmentDetailsPage')),
        permissionKey: 'school_management.can_view_configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/contract-payments',
        component: lazy(() => import('pages/sellers/SellerSchoolManagementContractPaymentsPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/institution-contracts',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementInstitutionContractPage'),
        ),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/institution-contracts/:institutionContractUid',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementInstitutionContractDetailsPage'),
        ),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/billing-rule/:collectionNotificationUid',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementCollectionNotificationDetailsPage'),
        ),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/billing-rule',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementCollectionNotificationPage'),
        ),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/configurations',
        component: lazy(() => import('pages/sellers/SellerSchoolManagementConfigurationPage')),
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/configurations/grades',
        component: lazy(() => import('pages/sellers/SellerSchoolManagementGradesPage')),
        permissionKey: 'school_management.can_view_configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/configurations/institution-enrollment-templates',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementEnrollmentTemplatesPage'),
        ),
        permissionKey: 'school_management.can_view_configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/configurations/institution-contract-templates',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementInstitutionContractTemplatePage'),
        ),
        permissionKey: 'school_management.can_view_configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/school-management/configurations/institution-contract-templates/:institutionContractTemplateUid',
        component: lazy(() =>
          import('pages/sellers/SellerSchoolManagementInstitutionContractTemplateDetailsPage'),
        ),
        permissionKey: 'school_management.can_view_configurations',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/forms',
        component: lazy(() => import('pages/sellers/SellerFormPage')),
        permissionKey: 'forms.can_view_forms',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/forms/:formUid',
        component: lazy(() => import('pages/sellers/SellerFormDetailsPage')),
        permissionKey: 'forms.can_view_forms',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sign/envelopes/:envelopeCode',
        component: lazy(() => import('pages/sellers/SellerSignEnvelopeDetailsPage')),
        permissionKey: 'sign.sign',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sign/envelopes',
        component: lazy(() => import('pages/sellers/SellerSignEnvelopePage')),
        permissionKey: 'sign.sign',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sign/envelope-templates/:envelopeTemplateCode',
        component: lazy(() => import('pages/sellers/SellerSignEnvelopeTemplateDetailsPage')),
        permissionKey: 'sign.sign',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug/sign/envelope-templates',
        component: lazy(() => import('pages/sellers/SellerSignEnvelopeTemplatePage')),
        permissionKey: 'sign.sign',
      },
      {
        exact: true,
        path: '/sellers/:sellerSlug',
        component: lazy(() => import('pages/sellers/SellerHomePage')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '/sellers',
    layout: EmptyLayout,
    guard: SellerGuard,
    component: lazy(() => import('pages/sellers/SellerChoosePage')),
  },
];
