import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Mui } from 'components';
import { BooleanField, DateField, NumberField } from '../DisplayData';

// Local Components

function Response({ children, type, value }) {
  if (children) return children;

  switch (type) {
    case 'str':
    case 'email':
    case 'phone':
    case 'cpf':
      return value;
    case 'int':
    case 'float':
      return <NumberField value={value} />;
    case 'bool':
      return (
        <Mui.Box>
          <BooleanField value={value} />
        </Mui.Box>
      );
    case 'date':
      return <DateField value={value} type="date" />;
    case 'datetime':
      return <DateField value={value} type="datetime" />;
    case 'file':
      return (
        <Mui.Chip
          clickable={!!value}
          color="primary"
          component="a"
          rel="noreferrer noopener"
          target="_blank"
          href={value}
          icon={<Mui.Icon fontSize="small">attach_file</Mui.Icon>}
          label={
            <Mui.Typography variant="caption" noWrap>
              {value?.match?.(/([^/])*(?=\?)/)?.[0]}
            </Mui.Typography>
          }
        />
      );
    case 'select':
      return value?.name;
    case 'multi_select':
      return value?.flatMap?.(item => (typeof item === 'string' ? item : item.name)).join(', ');
    case 'address':
      return [
        value?.line1,
        value?.line4,
        value?.line2,
        value?.line3,
        value?.postcode,
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        `${value?.city}/${value?.stateCode}${value?.countryCode ? ` - ${value.countryCode}` : ''}`,
      ]
        .filter(item => item)
        .join(', ');
    default:
      return children;
  }
}

Response.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
};

export default Response;
