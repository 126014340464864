import React from 'react';
import PropTypes from 'prop-types';
import { Mui, MuiLab } from 'components';

const useStyles = Mui.makeStyles(() => ({
  preLineCell: {
    whiteSpace: 'pre-line',
  },
}));

const Dynamic = ({ status, title, message }) => {
  // Hooks
  const classes = useStyles();

  // Constants
  const severityMapping = {
    'cancellation-requested': 'warning',
    canceled: 'error',
    denied: 'error',
    'simulation-denied': 'error',
  };
  const severity = severityMapping[status] || severityMapping.default;

  return (
    <MuiLab.Alert severity={severity}>
      {!!title && <MuiLab.AlertTitle>{title}</MuiLab.AlertTitle>}
      <Mui.Typography variant="body2" className={classes.preLineCell}>
        {message || `Status: ${status}`}
      </Mui.Typography>
    </MuiLab.Alert>
  );
};

Dynamic.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

export default Dynamic;
