import React from 'react';

export const serverError500Codes = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];

function objectErrorHandler(data) {
  const { detail, nonFieldErrors } = data;

  if (detail) return detail;

  if (nonFieldErrors && nonFieldErrors.length > 0) {
    const [error] = nonFieldErrors;
    return error;
  }

  return (
    <ul>
      {Object.keys(data).map((key, index) => {
        const value = data[key];
        const newValue = Array.isArray(value) ? value.join(' ') : value;
        return (
          <li key={`${index.toString()}`} style={{ listStyle: 'none' }}>
            <strong>{key}:</strong> {newValue}
          </li>
        );
      })}
    </ul>
  );
}

function errorHandler(data) {
  try {
    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((errorData, index) => (
            <li key={`${index.toString()}`} style={{ listStyle: 'none' }}>
              {objectErrorHandler(errorData)}
            </li>
          ))}
        </ul>
      );
    }
    if (data === Object(data)) {
      return objectErrorHandler(data);
    }
  } catch (error) {
    return 'Error';
  }

  return 'Ocorreu um erro desconhecido';
}

export default errorHandler;
