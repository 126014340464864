import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { IconButton, Mui, MuiLab, TextField } from 'components';
import { MaskedPostalCode } from 'components/common/InputMask';
import PlatformIcons from 'components/common/PlatformIcons';

// Local Components
import * as api from './api';

function InputPostcode({ formik, value, onChange, onPostalCodeCallback }) {
  // Constants
  const postCode = value || formik?.values.postcode;

  // Formik
  const formMessages = {
    postcode: { label: 'CEP', required: 'Não sabe o CEP? Procure no Google Maps :-)' },
  };

  // States
  const [searching, setSearching] = useState(false);
  const [unknown, setUnknown] = useState(false);

  // Actions
  const handlePostalCode = () => {
    setSearching(true);
    return api
      .searchPostalCode({ countryCode: 'BR', postalCode: postCode })
      .then(res => onPostalCodeCallback(res?.data))
      .catch(err => onPostalCodeCallback({ error: err }))
      .then(() => setSearching(false));
  };

  return (
    <>
      <TextField
        required
        name="postcode"
        type="text"
        margin="none"
        fullWidth={false}
        formik={formik}
        disabled={searching}
        messages={formMessages}
        value={value}
        onBlur={handlePostalCode}
        onChange={onChange ? e => onChange(e.target.value) : null}
        onKeyPress={ev => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            handlePostalCode();
          }
        }}
        InputProps={{
          inputComponent: MaskedPostalCode,
          endAdornment: (
            <Mui.InputAdornment position="end">
              <IconButton color="primary" onClick={handlePostalCode}>
                <PlatformIcons.Actions.Search />
              </IconButton>
            </Mui.InputAdornment>
          ),
        }}
      />
      {unknown && (
        <MuiLab.Alert severity="warning" onClose={() => setUnknown(false)}>
          <MuiLab.AlertTitle>CEP não localizado</MuiLab.AlertTitle>
          Não localizamos o CEP <strong>{postCode}</strong> em nossa base de endereços. Atenção ao
          preencher seu endereço completo abaixo
        </MuiLab.Alert>
      )}
    </>
  );
}

InputPostcode.propTypes = {
  formik: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onPostalCodeCallback: PropTypes.func,
};

export default InputPostcode;
