import * as yup from 'yup';

function createYupSchema(schema, validationTypes, validationType, uid) {
  if (!yup[validationTypes[validationType]?.type]) return schema;
  let validator = yup[validationTypes[validationType].type]();
  validationTypes[validationType].validations
    .filter(validation => validation)
    .forEach(validation => {
      const { params, type } = validation;
      if (!validator[type]) return;
      validator = validator[type](...params);
    });
  // eslint-disable-next-line no-param-reassign
  schema[uid] = validator;
  return schema;
}

export const createYupSchemaFormResponse = (schema, config) => {
  const { config: fieldConfig, isRequired, uid, type: validationType } = config;
  const { maxLength, minLength } = fieldConfig || {};
  const commonValidations = [];
  const configValidations = [];
  commonValidations.push({ type: 'nullable', params: [] });
  if (isRequired) commonValidations.push({ type: 'required', params: ['Campo obrigatório'] });
  if (minLength)
    configValidations.push({
      type: 'min',
      params: [minLength, `Campo deve conter no mínimo ${minLength} caracteres`],
    });
  if (maxLength)
    configValidations.push({
      type: 'max',
      params: [maxLength, `Campo deve conter no máximo ${maxLength} caracteres`],
    });
  const validationTypes = {
    str: { type: 'string', validations: [...commonValidations, ...configValidations] },
    email: { type: 'email', validations: [...commonValidations, ...configValidations] },
    phone: { type: 'string', validations: [...commonValidations, ...configValidations] },
    cpf: { type: 'string', validations: [...commonValidations, ...configValidations] },
    int: { type: 'number', validations: [...commonValidations, ...configValidations] },
    float: { type: 'number', validations: [...commonValidations, ...configValidations] },
    bool: { type: 'boolean', validations: [...commonValidations, ...configValidations] },
    date: { type: 'date', validations: [...commonValidations, ...configValidations] },
    datetime: { type: 'datetime', validations: [...commonValidations, ...configValidations] },
    file: { type: 'mixed', validations: [...commonValidations, ...configValidations] },
    select: { type: 'string', validations: [...commonValidations, ...configValidations] },
    multi_select: { type: 'array', validations: [...commonValidations, ...configValidations] },
    // address: { type: 'string', validations: [...commonValidations, ...configValidations] },
  };
  return createYupSchema(schema, validationTypes, validationType, uid);
};
