export function getFirstDayOfCurrentMonth() {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  firstDayOfMonth.setHours(0, 0, 0, 0);
  return firstDayOfMonth;
}

export function getLastDayOfCurrentMonth() {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  lastDayOfMonth.setHours(23, 59, 59, 999);
  return lastDayOfMonth;
}

export default function dateObject(dateString) {
  if (Number.isNaN(Date.parse(dateString)) || typeof dateString !== 'string') return dateString;
  return new Date(dateString.includes('T') ? dateString : `${dateString}T03:00:00Z`);
}
