// prettier-ignore
export const MASKS = {
  number: [...Array(999)].map(() => /\d/),
  taxDocumentCPF: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  taxDocumentCNPJ: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d?/],
  taxDocumentCPFCNPJ: rawValue =>
    rawValue.replace(/[^\d]/g, '').length > 11
      ? [/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/]
      : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  phoneNumber: rawValue =>
    rawValue.replace(/[^\d]/g, '').length > 10
      ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  fullPhoneNumber: rawValue => {
    const digits = rawValue.replace(/\D/g, '');
    const masks = {
      14: ['+', /\d/, /\d/, /\d/, ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      13: ['+', /\d/, /\d/, ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      12: ['+', /\d/, ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      11: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      10: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    };
    return masks[Math.min(digits.length, 14)] || masks[10];
  },
  postalCode:  [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  percentageNumber: rawValue =>
    rawValue.length < 2
      ? [/\d/]
      : [0, '.', /\d/, /\d/, /\d/, /\d/],
  time: [/\d/, /\d/, ':', /\d/, /\d/],
  ncm: [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/],
  threeDigits: [/\d/, /\d/, /\d/],
  fourDigits: [/\d/, /\d/, /\d/, /\d/],
  currencyBRL: rawValue => {
    if (rawValue.length < 3) return [/\d/, /\d/]
    const newValue = [...rawValue.replace(/[^\d]/g, '')].map(() => /\d/ )
    newValue.splice(-2, 0, ',')
    if (newValue.length > 6) newValue.splice(-6, 0, '.')
    if (newValue.length > 10) newValue.splice(-10, 0, '.')
    if (newValue.length > 14) newValue.splice(-14, 0, '.')
    if (newValue.length > 16) newValue.splice(-16, 1)
    return newValue
  },
};

export const unmask = value => {
  const stringValue = value.toString();
  return stringValue.replace(/\D/g, '');
};

export const maskPhone = value =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{2})(\d{5})(\d{4})\d+?$/, '+$1 ($2) $3-$4')
    .replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4')
    .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');

export const maskCPF = value =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const maskCNPJ = value =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const maskCPFCNPJ = value =>
  value.replace(/\D/g, '').length > 11 ? maskCNPJ(value) : maskCPF(value);

export const maskNCM = value =>
  value.replace(/\D/g, '').replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');

export const maskCEP = value =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
