import { Link as RouterLink } from 'react-router-dom';
import { Mui } from 'components';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  badge: { display: 'flex', alignItems: 'center' },
  badgeIcon: { marginRight: theme.spacing(1) },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  activities: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[100],
  },
}));

function Header(props) {
  const {
    className,
    overtitle,
    title,
    subtitle,
    tree,
    actions,
    cta,
    secondaryCta,
    homeTo,
    extra,
    activities,
    ...rest
  } = props;

  const classes = useStyles();

  const [activitiesOpen, setActivitiesOpen] = useState(false);

  return (
    <Mui.Grid
      className={clsx(classes.root, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}>
      <Mui.Grid item>
        {!!tree && (
          <Mui.Box mb={title ? 2 : 0} displayPrint="none">
            <Mui.Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              maxItems={3}>
              {homeTo && (
                <Mui.Link color="inherit" component={RouterLink} to={homeTo}>
                  <Mui.Icon>home</Mui.Icon>
                </Mui.Link>
              )}
              {tree.map((item, index) => {
                if (!item.to)
                  return (
                    <Mui.Typography key={`${index.toString()}`} variant="body1" color="textPrimary">
                      {item.label}
                    </Mui.Typography>
                  );
                return (
                  <Mui.Link
                    key={`${index.toString()}`}
                    variant="body1"
                    color="inherit"
                    to={item.to}
                    component={RouterLink}
                    target={item.target}>
                    {item.label}
                  </Mui.Link>
                );
              })}
            </Mui.Breadcrumbs>
          </Mui.Box>
        )}
        {!!overtitle && (
          <Mui.Typography variant="overline" color="primary">
            {overtitle}
          </Mui.Typography>
        )}
        {!!title && (
          <Mui.Typography variant="h3" color="textPrimary" gutterBottom={!!subtitle}>
            {title}
          </Mui.Typography>
        )}
        {!!subtitle && (
          <Mui.Typography variant="subtitle1" color="textSecondary">
            {subtitle}
          </Mui.Typography>
        )}
        {!!extra && (
          <Mui.Box mt={2}>
            <Mui.Grid container spacing={2}>
              {extra.map((item, index) => (
                <Mui.Grid item key={`${index.toString()}`}>
                  <Mui.Box color="text.secondary" width="100%">
                    <div className={classes.badge}>
                      <Mui.Icon fontSize="small" className={classes.badgeIcon}>
                        {item.icon}
                      </Mui.Icon>
                      <Mui.Typography variant="body2" color="inherit" component="span">
                        {item.label}
                      </Mui.Typography>
                    </div>
                  </Mui.Box>
                </Mui.Grid>
              ))}
            </Mui.Grid>
          </Mui.Box>
        )}
        {!!actions && (
          <Mui.Box mt={2}>
            {actions.map(action => (
              <Mui.Button
                key={action.key}
                onClick={action.onClick}
                disabled={action.disabled}
                startIcon={<Mui.Icon>{action.icon}</Mui.Icon>}>
                {action.label}
              </Mui.Button>
            ))}
          </Mui.Box>
        )}
      </Mui.Grid>
      {(!!secondaryCta || !!cta) && (
        <Mui.Grid item>
          <Mui.Box displayPrint="none">
            {secondaryCta && !secondaryCta.hidden && (
              <Mui.Button
                variant="outlined"
                color="secondary"
                startIcon={<Mui.Icon>{secondaryCta.icon}</Mui.Icon>}
                className={classes.action}
                onClick={secondaryCta.onClick}
                disabled={secondaryCta.disabled}
                disableElevation
                {...secondaryCta.buttonProps}>
                {secondaryCta.label}
              </Mui.Button>
            )}
            {!!cta && !cta.hidden && (
              <Mui.Button
                color="primary"
                variant="contained"
                startIcon={<Mui.Icon>{cta.icon}</Mui.Icon>}
                className={classes.action}
                onClick={cta.onClick}
                disabled={cta.disabled}
                {...cta.buttonProps}>
                {cta.label}
              </Mui.Button>
            )}
          </Mui.Box>
        </Mui.Grid>
      )}
      {!!activities && (
        <Mui.Grid item xs={12}>
          <Mui.Box className={classes.activities}>
            <Mui.Button
              variant="text"
              size="small"
              startIcon={<Mui.Icon>history</Mui.Icon>}
              onClick={() => setActivitiesOpen(true)}
              disabled={activities.disabled}
              {...activities.buttonProps}>
              Histórico de atividades
            </Mui.Button>
          </Mui.Box>
          {!!activities.Dialog &&
            activities.Dialog?.({
              url: activities.url,
              open: activitiesOpen,
              onClose: () => setActivitiesOpen(false),
              dialogProps: activities.dialogProps,
            })}
        </Mui.Grid>
      )}
    </Mui.Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  homeTo: PropTypes.string,
  overtitle: PropTypes.any,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  tree: PropTypes.array,
  actions: PropTypes.array,
  extra: PropTypes.array,
  cta: PropTypes.object,
  secondaryCta: PropTypes.object,
  activities: PropTypes.object,
};

export default Header;
