import { MASKS } from 'utils/masks';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import * as React from 'react';

function TextMaskInput({ inputRef, mask, ...rest }) {
  return (
    <MaskedInput
      {...rest}
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      guide={false}
      mask={mask}
    />
  );
}

TextMaskInput.propTypes = {
  inputRef: PropTypes.any,
  mask: PropTypes.any,
};

const MaskedTaxDocumentCPF = props => <TextMaskInput {...props} mask={MASKS.taxDocumentCPF} />;
const MaskedTaxDocumentCNPJ = props => <TextMaskInput {...props} mask={MASKS.taxDocumentCNPJ} />;
const MaskedTaxDocumentCPFCNPJ = props => (
  <TextMaskInput {...props} mask={MASKS.taxDocumentCPFCNPJ} />
);
const MaskedNumber = props => <TextMaskInput {...props} mask={MASKS.number} />;
const MaskedPhoneNumber = props => <TextMaskInput {...props} mask={MASKS.phoneNumber} />;
const MaskedFullPhoneNumber = props => <TextMaskInput {...props} mask={MASKS.fullPhoneNumber} />;
const MaskedPostalCode = props => <TextMaskInput {...props} mask={MASKS.postalCode} />;
const MaskedPercentageNumber = props => <TextMaskInput {...props} mask={MASKS.percentageNumber} />;
const MaskedTime = props => <TextMaskInput {...props} mask={MASKS.time} />;
const MaskedNCM = props => <TextMaskInput {...props} mask={MASKS.ncm} />;
const Masked3Digits = props => <TextMaskInput {...props} mask={MASKS.threeDigits} />;
const Masked4Digits = props => <TextMaskInput {...props} mask={MASKS.fourDigits} />;
const MaskedCurrencyBRL = props => <TextMaskInput {...props} mask={MASKS.currencyBRL} />;

export {
  MaskedNumber,
  MaskedPercentageNumber,
  MaskedPhoneNumber,
  MaskedFullPhoneNumber,
  MaskedPostalCode,
  MaskedTaxDocumentCNPJ,
  MaskedTaxDocumentCPF,
  MaskedTaxDocumentCPFCNPJ,
  MaskedTime,
  MaskedNCM,
  Masked3Digits,
  Masked4Digits,
  MaskedCurrencyBRL,
};
