import api from 'utils/api';

export const endpoints = {
  searchPostalCode: (countryCode, postalCode) =>
    `/commons/postal-code/${countryCode}/${postalCode}/`,
};

/**
 * Documentation: https://eskolare.postman.co/collections/6419581-57cfa319-241f-4284-af4b-e0159432c584?version=latest&workspace=33e9d0fe-9d33-47cf-ad90-a76a685364de#683b013e-953a-449e-a802-775c9abe052a
 */
export function searchPostalCode({ countryCode, postalCode }) {
  const config = {
    method: 'get',
    url: endpoints.searchPostalCode(countryCode, postalCode),
  };
  return api(config);
}
